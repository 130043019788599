define("discourse/plugins/discourse-saved-searches/discourse/controllers/preferences-saved-searches", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _ajax, _ajaxError, _computed, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    savedSearches: null,
    isSaving: false,
    saved: false,
    canAddSavedSearch: (0, _computed.propertyLessThan)("savedSearches.length", "siteSettings.max_saved_searches"),
    savedSearchFrequencyOptions() {
      return [{
        name: _I18n.default.t("saved_searches.frequency_options.immediately"),
        value: "immediately"
      }, {
        name: _I18n.default.t("saved_searches.frequency_options.hourly"),
        value: "hourly"
      }, {
        name: _I18n.default.t("saved_searches.frequency_options.daily"),
        value: "daily"
      }, {
        name: _I18n.default.t("saved_searches.frequency_options.weekly"),
        value: "weekly"
      }];
    },
    addSavedSearch() {
      this.savedSearches.pushObject({
        query: "",
        frequency: "daily"
      });
    },
    removeSavedSearch(savedSearch) {
      this.savedSearches.removeObject(savedSearch);
    },
    save() {
      this.setProperties({
        isSaving: true,
        saved: false
      });
      const savedSearches = this.savedSearches.filter(savedSearch => !!savedSearch.query.trim());
      return (0, _ajax.ajax)(`/u/${this.model.username}/preferences/saved-searches`, {
        type: "PUT",
        dataType: "json",
        data: {
          searches: savedSearches
        }
      }).then(() => this.setProperties({
        saved: true,
        "model.saved_searches": savedSearches
      })).catch(_ajaxError.popupAjaxError).finally(() => this.set("isSaving", false));
    }
  }, (_applyDecoratedDescriptor(_obj, "savedSearchFrequencyOptions", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "savedSearchFrequencyOptions"), _obj), _applyDecoratedDescriptor(_obj, "addSavedSearch", [_object.action], Object.getOwnPropertyDescriptor(_obj, "addSavedSearch"), _obj), _applyDecoratedDescriptor(_obj, "removeSavedSearch", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removeSavedSearch"), _obj), _applyDecoratedDescriptor(_obj, "save", [_object.action], Object.getOwnPropertyDescriptor(_obj, "save"), _obj)), _obj));
});